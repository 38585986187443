import React from 'react'
import PropTypes from 'prop-types'

import { UIText } from '../../../UI'

const ExtraRow = (props) => (
  <li row center>
    <UIText
      size="14px"
      color="black"
      align="left"
      text={props.label}
    />
  </li>
)

ExtraRow.propTypes = {
  label: PropTypes.string.isRequired
}

export default ExtraRow
