import React from 'react'
import { map, add, range, indexOf, reverse, compose, equals } from 'ramda'
import PropTypes from 'prop-types'
import { UILayout, UIText, UIIcon } from '../UI'

const colorDefault = '#cfd8dc'
const colorSelected = '#90a4ae'

const PageItem = (props) => {
  const { pageItemStyle, pageNumber, selected, onSelectPage } = props
  return (
    <UILayout
      center
      j-flex-center
      cursor="pointer"
      borderRadius="100%"
      width="24px"
      margin="0 8px 0 0"
      bgColor={selected ? colorSelected : colorDefault}
      onClick={() => onSelectPage(pageNumber)}
      {...pageItemStyle}
    >
      <UIText lineHeight="24px" color="#FFFFFF" {...pageItemStyle.textStyle}>{pageNumber}</UIText>
    </UILayout>
  )
}

PageItem.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  pageItemStyle: PropTypes.object
}

PageItem.defaultProps = {
  selected: false,
  pageItemStyle: {}
}

const PageStepButton = ({ text, icon, style, iconStyle }) => (
  <UILayout cursor="pointer" width="auto" style={style}>
    <UIIcon
      type={icon}
      margin="0 6px"
      style={{
        width: '10px',
        height: '15px',
        fill: '#4266bd',
        ...iconStyle
      }}
    />
    <UIText lineHeight="24px" color="#000000" text={text} />
  </UILayout>
)

PageStepButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  style: PropTypes.object,
  iconStyle: PropTypes.object
}

PageStepButton.defaultProps = {
  text: '',
  icon: 'iconChevron',
  style: {},
  iconStyle: {}
}

const Pagination = (props) => {

  const {
    selectedPage,
    maxVisiblePages,
    pageCount,
    pageItemStyle,
    prevText,
    nextText,
    hideStepButtons,
    paginationStyle,
    onSelectPage } = props

  const pages = calcPagesInterval(selectedPage, pageCount, maxVisiblePages)

  return (
    <UILayout {...paginationStyle}>
      <UILayout width="auto">
        {
          map((page) => (
            <PageItem
              key={`page-item-${page}`}
              pageNumber={page}
              selected={equals(page, selectedPage)}
              onSelectPage={onSelectPage}
              pageItemStyle={pageItemStyle}
            />
          ), pages)
        }
      </UILayout>
      <UILayout xs={0} display-if={!hideStepButtons}>
        <UILayout cursor="pointer" width="auto" onClick={() => selectPageIfValid(selectedPage - 1, pageCount, onSelectPage)}>
          <PageStepButton text={prevText} iconStyle={{ transform: 'rotate(180deg)' }} />
        </UILayout>
        <UIText lineHeight="24px" color="#cfd8dc" margin="0 8px">|</UIText>
        <UILayout cursor="pointer" width="auto" onClick={() => selectPageIfValid(selectedPage + 1, pageCount, onSelectPage)}>
          <PageStepButton text={nextText} style={{ flexDirection: 'row-reverse' }} />
        </UILayout>
      </UILayout>
    </UILayout>
  )
}


const selectPageIfValid = (page, totalPages, onSelectPage) => {
  if (page < 1 || page > totalPages) return
  onSelectPage(page)
}

const calcPagesInterval = (selectedPage, pageCount, maxVisiblePageCount) => {
  const visiblePageCount = Math.min(maxVisiblePageCount, pageCount)
  const delta = Math.floor(visiblePageCount / 2)

  const initialInterval = range(0, visiblePageCount)
  const centredInterval = map(add(selectedPage - delta))(initialInterval)
  const shiftRangeLeftCount = indexOf(1)(centredInterval)
  const shiftRangeRightCount = compose(indexOf(+pageCount), reverse)(centredInterval)

  if (equals(shiftRangeLeftCount, shiftRangeRightCount)) {
    return centredInterval
  }
  return map(add(shiftRangeLeftCount * Math.abs(shiftRangeRightCount)))(centredInterval)
}

Pagination.propTypes = {
  onSelectPage: PropTypes.func.isRequired,
  selectedPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  maxVisiblePages: PropTypes.number,
  prevText: PropTypes.string,
  nextText: PropTypes.string,
  pageItemStyle: PropTypes.object,
  hideStepButtons: PropTypes.bool,
  paginationStyle: PropTypes.object
}

Pagination.defaultProps = {
  maxVisiblePages: 5,
  prevText: 'Previous',
  nextText: 'Next',
  pageItemStyle: {},
  hideStepButtons: false,
  paginationStyle: {}
}

export default Pagination
