import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIText, UIIcon } from '../UI'

const selectedStyle = {
  bgColor: '#0eb1bc',
  borderRadius: '15px',
  boxShadow: 'inset 0 1px 3px 0 #ffffff'
}

const selectedTextStyle = {
  color: 'white'
}

// eslint-disable-next-line react/prefer-stateless-function
class ButtonGroup extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {
      activeValue: props.activeValue || props.buttons[0].value
    }
  }

  handleChange = (activeValue) => {
    const { onChange, disabled } = this.props

    if (!disabled) {
      this.setState({ activeValue })
      if (onChange) onChange(activeValue)
    }
  };

  handleControlClick = (index, event) => {
    event.stopPropagation()

    const { buttons, disabled } = this.props
    const button = buttons[index]

    if (button && !disabled) this.handleChange(button.value)
  }

  render() {
    const { muiTheme } = this.context
    const { id = 'buttonGroup', buttons, prefix, withControls, editMode } = this.props
    const customThemeProps = muiTheme.ids[id] || {}

    const controlsStyles = editMode ?
      { margin: '1px', width: '12px', height: '12px' }
      : { margin: '3px', width: '20px', height: '20px' }

    return (
      <UILayout
        height="31px"
        bgColor="white"
        borderRadius="15px"
        boxShadow="inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)"
      >
        {buttons.map((button, index) => {
          let extraStyle = { }
          let extraTextStyle = { whiteSpace: 'nowrap', color: '#bfc3c7' }
          const buttonIsActive = button.value === this.state.activeValue

          if (buttonIsActive) {
            extraStyle = { ...extraStyle, ...selectedStyle, ...customThemeProps.selectedButtonStyle }
            extraTextStyle = { ...extraTextStyle, ...selectedTextStyle }

            if (withControls) {
              extraStyle = { ...extraStyle, 'j-flex-space-between': true }
            }
          }

          return (
            <UILayout
              center
              j-flex-center
              cursor="pointer"
              onClick={() => this.handleChange(button.value)}
              {...extraStyle}
              xsl={{ 'flex-center': true, 'j-flex-space-between': false }}
            >
              <UILayout
                onClick={(event) => this.handleControlClick(index - 1, event)}
                center
                {...controlsStyles}
                display-if={(withControls && !editMode) || buttonIsActive}
                xs={{ width: '14px', height: '14px' }}
                xsl={0}
              >
                <UIIcon type="remove" fill="white" width="12px" />
              </UILayout>
              <UILayout xs={0} center j-flex-center>
                <UIText
                  {...extraTextStyle}
                  size={editMode ? '14px' : '16px'}
                >
                  {`${(prefix && buttonIsActive) ? `${prefix}: ${button.title}` : button.title}`}
                </UIText>
              </UILayout>
              <UILayout xl={0} xs={1} center  j-flex-center>
                <UIText
                  {...extraTextStyle}
                  size="12px"
                >
                  {button.title}
                </UIText>
              </UILayout>
              <UILayout
                onClick={(event) => this.handleControlClick(index + 1, event)}
                center
                {...controlsStyles}
                display-if={(withControls && !editMode) || buttonIsActive}
                xs={{ width: '14px', height: '14px' }}
                xsl={0}
              >
                <UIIcon type="add" fill="white" width="12px" />
              </UILayout>
            </UILayout>
          )
        })}
      </UILayout>
    )
  }
}

ButtonGroup.propTypes = {
  buttons: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  activeValue: PropTypes.string,
  withControls: PropTypes.bool,
  disabled: PropTypes.bool,
  editMode: PropTypes.bool
}

ButtonGroup.defaultProps = {
  prefix: '',
  activeValue: '',
  disabled: false,
  withControls: false,
  onChange: () => {},
  editMode: false
}

ButtonGroup.contextTypes = {
  muiTheme: PropTypes.object
}

export default ButtonGroup
