import React from 'react'
import { propEq, cond, T, identity } from 'ramda'
import PropTypes from 'prop-types'
import TextFieldMaterialUI from 'material-ui/TextField'

const requiredProps = {
  inputStyle: {
    border: '1px red solid'
  },
  errorStyle: {
    display: 'block',
    position: 'absolute',
    bottom: '-22px',
    fontSize: '14px'
  }
}

const getTextFieldParameters = (props, context) => {
  const { muiTheme } = context
  const { id, style, inputStyle, hintStyle, errorStyles, wrapperStyles, ...params } = props

  let customThemeProps = {}
  if (muiTheme) customThemeProps = muiTheme.ids[id] || {}

  const defaultProps = {
    style: {
      height: '44px',
      backgroundColor: 'white',
      boxShadow: '0 11px 6px 0 rgba(0, 0, 0, 0.04), 0 6px 4px 0 rgba(0, 0, 0, 0.02)',
      fontSize: '18px',
      borderRadius: 'inherit',
      ...style
    },
    inputStyle: {
      color: 'black',
      padding: '1px 14px 0',
      boxSizing: 'border-box',
      border: 'none',
      ...inputStyle
    },
    hintText: 'Hint text',
    hintStyle: {
      color: '#dedede',
      paddingTop: '1px',
      paddingLeft: '14px',
      display: 'flex',
      alignItems: 'center',
      bottom: 'auto',
      height: '100%',
      ...hintStyle
    },
    errorText: 'This field is required',
    errorStyle: {
      display: 'none'
    },
    fullWidth: true,
    required: false,
    disabled: false,
    type: 'text', // password, tel, email, url...
    underlineShow: false
  }

  const internals = {
    ...defaultProps,
    ...customThemeProps,
    ...params
  }


  return internals
}

const keyTypeOf = propEq('key')

class TextField extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {}
  }


  render() {
    const internals = getTextFieldParameters(this.props, this.context)
    const { change, blur, ...params } = internals

    const specialHandlers = [
      [keyTypeOf('ArrowDown'), (e) => {
        e.preventDefault()
        e.target.blur()
      }]
    ]

    const basicHandlers = [
      [keyTypeOf('Enter'), (e) => {
        e.preventDefault()
        e.target.blur()
      }],
      [keyTypeOf('Escape'), (e) => {
        this.nameInput.input.value = this.initValue || ''
        e.target.blur()
      }]
    ]

    const defaultCase = [T, identity]

    const keyHandler = cond([
      ...basicHandlers,
      ...specialHandlers,
      defaultCase
    ])

    return (
      <TextFieldMaterialUI
        {...internals}
        name={this.props.id}
        ref={(node) => {
          this.nameInput = node
        }}
        onChange={(e, val) => { if (change) change(val) }}
        onKeyDown={keyHandler}
        onBlur={(e) => {
          if (this.props.onBlur) {
            this.props.onBlur(e)
            return
          }
          const val = this.nameInput.input.value
          // eslint-disable-next-line no-param-reassign
          if (val !== this.initValue) e = null
          if (blur) blur(val)

          if (params.required && val === '') {
            this.setState({ ...params, ...requiredProps })
          }
        }}
        onFocus={(e) => {
          if (this.props.onFocus) {
            this.props.onFocus(e)
            return
          }
          if (this.nameInput && this.nameInput.input) {
            this.initValue = this.nameInput.input.value

            this.setState({ ...params })
          }
        }}
      />
    )
  }
}

TextField.contextTypes = {
  muiTheme: PropTypes.object
}

TextField.propTypes = {
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  id: PropTypes.string.isRequired
}


export default TextField
