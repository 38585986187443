import React from 'react'
import PropTypes from 'prop-types'

import { UIIncDecButton, UIButton, UILayout, UIText } from '../../UI'

const ParamCounter = (props) => (
  <UILayout column flex-start width="auto" margin="0 0 0 auto">
    { props.soldout ? (
        <UIButton
          disabled
          width="100%"
          height="36px"
          border-radius="7px"
          display-if={props.soldout}
        >
            <UIText align="center" size="16px" textTransform="uppercase">{props.soldout}</UIText>
        </UIButton>
      ) : (
        <UIIncDecButton
          id="IncDecButton"
          align="right"
          minValue={0}
          maxValue={props.maxValue}
          initValue={props.initValue}
          select={(value) => props.select(value)}
        />
      )
    }
  </UILayout>
)

ParamCounter.propTypes = {
  initValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  select: PropTypes.func.isRequired,
  soldout: PropTypes.string.isRequired,
}

export default ParamCounter
