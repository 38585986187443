import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card, CardHeader, CardMedia } from 'material-ui/Card'
import { UIIcon, UILayout, UIText, UIImage } from '../UI'


const StyledCardHeader = styled(CardHeader)`
        background-color: ${(props) => `${props.background} !important`};
        height: ${(props) => `${props.height} !important`};
        padding: ${(props) => `${props.padding} !important`};
        
        & > div:first-child {
           display: none !important;
        }
        button:first-of-type {
            right: ${(props) => `${props.buttonRight} !important`};
        }
    `
StyledCardHeader.defaultProps = {
  background: '#f3f3f3',
  height: '70px',
  padding: '0 26px 0 40px',
  buttonRight: '20px'
}

const getAccordionParameters = (props, context) => {
  const { muiTheme } = context
  const { id, ...params } = props
  const customThemeProps = muiTheme.ids[id] || {} // Style & Props from -- frontend\src\stories\.themes\theme.json

  const defaultProps = {
    expanded: false,
    expandable: true,               // If true, this card component is expandable.
    actAsExpander: true,            // If true, a click on this card component expands the card.
    showExpandableButton: true,
    cardStyle: {
      width: '100%',
      backgroundColor: 'transparent',
      boxShadow: '',
      margin: '0 0 2px',
      zIndex: '0'
    },
    cardMediaStyle: {
      background: '#fafafa'
    },
    iconSrc: 'iconPassenger',       // .svg icon or path to image lke 'images/icon-passenger.png'
    iconMargin: '0',
    title: 'Passenger Ticket',
    titleSize: '24px',
    titleColor: 'black',
    titlePadding: '0 0 0 80px'
  }

  const internals = {
    ...defaultProps,
    ...customThemeProps,
    ...params
  }


  return internals
}

const HeadDefaultContent = (props) => (
  <UILayout j-flex-start center height="100%">
    {props.headIconNode}
    <UIText
      size={props.titleSize}
      position={'absolute'}
      padding={props.titlePadding}
      xs={{ padding: '0 0 0 42px', size: '22px' }}
      color={props.titleColor}
      userSelect="none"
    >{props.title}</UIText>
  </UILayout>
    )

HeadDefaultContent.propTypes = {
  headIconNode: PropTypes.any,
  titleSize: PropTypes.any,
  titlePadding: PropTypes.string,
  titleColor: PropTypes.string,
  title: PropTypes.string
}

class Accordion extends React.Component {
  constructor(props, context) {
    super(props, context)
    const internals = getAccordionParameters(props, context)
    this.state = {
      expanded: internals.expanded,
      mediaHeight: '0'
    }
  }

  componentDidMount() {
    const internals = getAccordionParameters(this.props, this.context)
    this.handleExpandChange(internals.expanded)
  }

  handleExpandChange = (expanded) => {
    this.setState({ expanded })

    const contentHeight = this.divElement ? this.divElement.clientHeight : 'auto'
    this.setState({ mediaHeight: expanded ? contentHeight : 0 })

    const internals = getAccordionParameters(this.props, this.context)
    const click = internals.click
    if (click) click(expanded)
  }

  render() {
    const internals = getAccordionParameters(this.props, this.context)
    const { id, expanded, expandable, actAsExpander, showExpandableButton, headNode, iconSrc, iconMargin, title, titleSize, titlePadding, titleColor, cardStyle, cardMediaStyle, children, ...params } = internals

    let headIconNode
    if (/[.]/i.test(iconSrc)) {
      headIconNode =
        (<UILayout margin={iconMargin} xs={{ margin: '0 0 0 -8px' }}>
          <UIImage src={iconSrc} />
        </UILayout>)
    } else {
      headIconNode =
        <UIIcon type={iconSrc} margin={iconMargin} xs={{ margin: '0 0 0 -8px' }} />
    }

    return (
      <Card
        {...params}
        expanded={this.state.expanded}
        style={cardStyle}
        onExpandChange={this.handleExpandChange}
      >
        <StyledCardHeader
          {...params}
          actAsExpander={actAsExpander}
          showExpandableButton={showExpandableButton}
          openIcon={<UIIcon type="iconChevronDown" flipV />}
          closeIcon={<UIIcon type="iconChevronDown" />}
        >
          {headNode && headNode !== '' ? headNode : <HeadDefaultContent headIconNode={headIconNode} {...internals} />}
        </StyledCardHeader>
        <CardMedia
          style={Object.assign({}, cardMediaStyle, { overflow: 'hidden', transition: 'height 0.2s ease-in-out', height: this.state.mediaHeight })}
        >
          <div ref={(divElement) => { this.divElement = divElement }} >
            {internals.children}
          </div>
        </CardMedia>
      </Card>
    )
  }
}

Accordion.contextTypes = {
  muiTheme: PropTypes.object
}

Accordion.propTypes = {}


export default Accordion
