import React from 'react'
import PropTypes from 'prop-types'
// import styled, { css } from 'styled-components'
import ToggleMaterialUI from 'material-ui/Toggle'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { UILayout } from '../UI';

/* const SizedToggle = styled(ToggleMaterialUI)`
    width: ${props => props.width};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    background: ${props => props.background};
    `
SizedToggle.defaultProps = {
    size:   'inherit',
    color:  'inherit',
    width:  'inherit',
    background: 'yellow'
} */

const getToggleParameters = (props, context) => {
  const { muiTheme } = context
  const { id, ...params } = props
  const customThemeProps = muiTheme.ids[id] || {}

  const defaultProps = {
    labelPosition: 'right',
    defaultToggled: false,
    disabled: false,
    labelStyle: { fontSize: '16px', color: 'white', marginLeft: '10px', textAlign: 'left', textTransform: 'uppercase' }
  }

  customThemeProps.labelStyle = Object.assign(defaultProps.labelStyle, customThemeProps.labelStyle)

  return {
    ...defaultProps,
    ...customThemeProps,
    ...params
  }
}

class Toggle extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.internals = getToggleParameters(props, context)
    this.state = { label: this.internals.defaultToggled ? this.internals.labelOn : this.internals.labelOff }

    this.handleChangeLabel = this.handleChangeLabel.bind(this)
  }

  handleChangeLabel(event, val) {
    if (this.props.select) this.props.select(val)
    this.setState({
      label: val
                ? this.props.labelOn
                : this.props.labelOff
    })
  }

  handleRadioButtonClick = () => {
    const { labelOn, labelOff } = this.props;
    const val = this.state.label !== labelOn;

    if (this.props.select) this.props.select(val)
    this.setState({ label: val ? this.props.labelOn : this.props.labelOff })
  }

  render() {
    this.internals = getToggleParameters(this.props, this.context)

    this.internals.label = this.state.label
    const { id, ...params } = this.internals

    return (
      <UILayout width="auto">
        <UILayout xs={1} xl={0}>
          <ToggleMaterialUI
            key={id}
            {...params}
            onToggle={this.handleChangeLabel}
          />
        </UILayout>
        <UILayout xs={0} xl={1}>
          <UILayout
            center
            width="auto"
            height="48px"
            minWidth="105px"
            bgColor="#20bbd1"
            borderRadius="24px"
            padding="14px 24px 14px 14px"
            onClick={this.handleRadioButtonClick}
          >
            <RadioButtonGroup name="checked" valueSelected={this.state.label}>
              <RadioButton
                value={this.props.labelOn}
                label={this.state.label}
                labelStyle={{ color: 'white' }}
                iconStyle={{ fill: 'white' }}
              />
            </RadioButtonGroup>
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

Toggle.contextTypes = {
  muiTheme: PropTypes.object
}

Toggle.propTypes = {
  select: PropTypes.func.isRequired,
  labelOn: PropTypes.string.isRequired,
  labelOff: PropTypes.string.isRequired
}

export default Toggle
