import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { defaultProps, componentFromProp } from 'recompose'

import ParamCounter from './ParamCounter'
import { Divider, GroupHeader } from './components'
import { Auto, ImageAndExtra, Description } from './ParamDetails'
import { UIAccordion, UILayout } from '../../UI'

const DetailsComponents = {
  iconDishes: ImageAndExtra,
  iconCabin: ImageAndExtra,
  iconPassenger: Description,
  iconCar: Auto,
  iconTruck: Description,
}

const enhanceComp = defaultProps({ component: 'Dishes' })
const Details = enhanceComp(componentFromProp('component'))

// eslint-disable-next-line react/prefer-stateless-function
export default class TicketParam extends PureComponent {
  render() {
    const { title, iconSrc, properties, expanded } = this.props

    return (
      <UIAccordion
        id="accordion"
        title={title}
        iconSrc={iconSrc}
        expanded={expanded}
        iconMargin="0 0 0 10px"
        display-if={properties.length > 0}
      >
        <UILayout
          column
          j-flex-start
          padding="0 0 0 36px"
          xs={{ padding: '0' }}
        >
          {properties.map(this.renderProperties)}
        </UILayout>
      </UIAccordion>
    )
  }

  renderProperties = (property, index) => {
    if (property.properties) {
      return (
        <UILayout column>
          <GroupHeader
            name={property.name}
            description={property.description}
            image={property.image}
          />
          {property.properties && property.properties.map(this.renderProperty)}
        </UILayout>
      )
    }
    return this.renderProperty(property, index)
  }

  renderProperty = (property, index) => [
    <UILayout
      center
      width="auto"
      padding="24px 25px 26px 0"
      xs={{ padding: '24px 25px 26px 36px' }}
    >
      <Details
        component={DetailsComponents[this.props.iconSrc]}
        {...property}
      />
      <ParamCounter
        key={property.code}
        initValue={property.count}
        maxValue={property.canAdd + property.count}
        select={(value) => this.props.changeProperty(property, value, index)}
        soldout={property.soldOut}
      />
    </UILayout>,
    <Divider index={index} length={property.length} />,
  ]
}

TicketParam.propTypes = {
  title: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  properties: PropTypes.array,
  changeProperty: PropTypes.func.isRequired,
}

TicketParam.defaultProps = {
  properties: [],
  expanded: false,
  changeProperty: () => {},
}
