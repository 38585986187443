/* eslint-disable react/style-prop-object */
import React from 'react'
import PropTypes from 'prop-types'

import { ExtraRow } from '../components'
import { UIImage, UILayout, UIFormattedNumber, UIText } from '../../../UI'


const ImageAndExtra = (props) => (
  <UILayout row width="100%" padding="0 30px 0 0">
    <UILayout height="96px" width="auto" minWidth="96px" maxWidth="96px" xs={{ height: '84px' }}>
      <UIImage src={props.image} width="100%" />
    </UILayout>
    <UILayout column j-flex-space-between margin="0px 0px 0px 15px">
      <UILayout column>
        <UIFormattedNumber
          size="24px"
          weight="bold"
          style="currency"
          value={props.price}
          minimumFractionDigits={0}
          currency={props.currency.code}
        />
        <UIText
          size="18px"
          color="black"
          weight="bold"
          align="left"
        >
          {props.title}&nbsp;
        </UIText>
      </UILayout>
      <UILayout column>
        {props.extra.map((label) => <ExtraRow label={label} />)}
      </UILayout>
    </UILayout>
  </UILayout>
)

ImageAndExtra.propTypes = {
  image: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  extra: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired
}

ImageAndExtra.defaultProps = {
  extra: []
}

export default ImageAndExtra
