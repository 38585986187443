
import React from 'react'
import PropTypes from 'prop-types'
import DividerMaterialUI from '@material-ui/core/Divider'
import styled from 'styled-components'

const StyledDivider = styled(DividerMaterialUI)`
        background-color: ${(props) => `${props.bgColor} !important`};
        height: ${(props) => `${props.vertical && !props.height ? 'inherit' : props.height} !important`};
        width: ${(props) => `${props.width} !important`};
        margin: ${(props) => `${props.margin} !important`};
        flex-shrink: ${(props) => `${props.shrink} !important`};
        visibility: ${(props) => `${props.visible ? 'inherit' : 'hidden'} !important`};
    `
StyledDivider.defaultProps = {
  height: '1px',
  width: '1px',
  bgColor: '#ededed',
  margin: '0',
  shrink: '0'
}


const Divider = (props, context) => {
  const { muiTheme } = context
  const { id = 'divider', ...params } = props
  const customThemeProps = muiTheme.ids[id] || {} // Style & Props from -- frontend\src\stories\.themes\theme.json

  const defaultProps = {
    inset: false
  }

  const internals = {
    ...defaultProps,
    ...customThemeProps,
    ...params
  }

  return <StyledDivider {...internals} />
}

Divider.contextTypes = {
  muiTheme: PropTypes.object
}

Divider.propTypes = {
  id: PropTypes.string
}


export default Divider
