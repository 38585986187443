/* eslint-disable react/no-multi-comp */
import React from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { keys } from 'ramda'
import { getMediaQueryies } from './utils/MediaQueryes'

export const oReverseMap = (obj, f) =>
  Object.assign(
    ...Object.keys(obj)
      .reverse()
      .map((k) => ({ [k]: f(obj[k], k) }))
  )

const mergeProps = createSelector(
  [(props) => props, (props, context) => context.muiTheme.ids[props.id], () => ({})],
  (props, customThemeProps, empty) => ({ ...(customThemeProps || empty), ...props })
)

const getResponsiveProps = (breakpoints, media, props) => {
  let previousBreakpointProps = true

  const propsByBreakpoints = oReverseMap(breakpoints, (val, value) => {
    if (props[value] === undefined) return previousBreakpointProps
    previousBreakpointProps = props[value]
    return previousBreakpointProps
  })

  const isBoolean = !!media.length && typeof propsByBreakpoints[media[media.length - 1]] === 'boolean'
  const isRender = !!media.length && propsByBreakpoints[media[media.length - 1]]
  // todo: please fix the lint error here
  const responsiveProps = media.length
    ? isBoolean
      ? { isRender }
      : { isRender, ...propsByBreakpoints[media[media.length - 1]] }
    : { isRender }

  return responsiveProps
}

/* We have the following props
 High priority
 React responsive props <xs={{a:"3", b: "4"}}> if current width is equal or less xs
 Theme responsive props <id="1"> theme.json: { ids: { "1": {a: "5", b:"6", xs:{a:"7", b:"8"}}}}
 Theme responsive props by ref <id="1"> { ids: { "1": {a: "5", b:"6", xs:{id:"1-responsive"}}, "1-responsive": {a:"9", b: "10"}}}
 React props <a="1" b="2">
 Theme props <id="1"> { ids: { "1": {a: "5", b:"6"}}}
 Low Priority
 */

const ResponsiveProps = () => (DecoratedComponent) => {
  const ResponsiveClass = class extends React.Component {
    static contextTypes = {
      muiTheme: PropTypes.object,
    }

    render() {
      const {
        muiTheme: { breakpoints },
      } = this.context

      // Getting responsive params
      const media = keys(breakpoints)
      // Extend props with props from theme to get all not responsive props
      const withThemeProps = mergeProps(this.props, this.context)
      // Extend all not responsive props with responsive props to get all responsive props
      const props = { ...withThemeProps, ...getResponsiveProps(breakpoints, media, withThemeProps) }

      const componentMedias = media.filter((scale) => props.hasOwnProperty(scale))

      if (componentMedias.length === 0) {
        return <DecoratedComponent {...props} />
      }

      const componentSizes = componentMedias.map((cMedia) => breakpoints[cMedia])

      return getMediaQueryies(props, componentMedias, componentSizes, DecoratedComponent)
    }
  }

  return React.forwardRef((props, ref) => <ResponsiveClass {...props} forwardedRef={ref} />)
}

// eslint-disable-next-line react/no-multi-comp,react/prefer-stateless-function
export const AbstractPureComponent = (contextComponentName) => () => {
  class Component extends React.Component {
    static propTypes = {
      forwardedRef: PropTypes.any,
    }

    static contextTypes = {
      [contextComponentName]: PropTypes.any,
    }

    render() {
      const { forwardedRef, ...props } = this.props
      const ContextComponent = this.context[contextComponentName]

      // for withStyles component
      if (contextComponentName === 'DropDownMenu') {
        return <ContextComponent innerRef={forwardedRef} {...props} />
      }

      return <ContextComponent ref={forwardedRef} {...props} />
    }
  }

  return React.forwardRef((props, ref) => <Component {...props} forwardedRef={ref} />)
}

export default ResponsiveProps
