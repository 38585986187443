import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { UILayout } from '../UI'

const Wrapper = (props) =>
  props.text ? (
    <span onClick={props.onClick} className={props.className} data-test={props['data-test']}>
      {props.text}
    </span>
  ) : (
    <div onClick={props.onClick} className={props.className} data-test={props['data-test']}>
      {props.children}
    </div>
  )

Wrapper.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string.isRequired,
  children: PropTypes.any,
}

const StyledText = styled(Wrapper)`
  display: ${(props) => props.display};
  position: ${(props) => props.position};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  font-size: ${(props) => props.size};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  font-family: ${(props) => props.font};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  text-align: ${(props) => props.align};
  opacity: ${(props) => props.opacity};
  box-sizing: ${(props) => props.boxSizing};
  text-transform: ${(props) => props.textTransform};
  letter-spacing: ${(props) => props.letterSpacing};
  user-select: ${(props) => props.userSelect};
  cursor: ${(props) => props.cursor};
  white-space: ${(props) => props.whiteSpace};
  line-height: ${(props) => props.lineHeight};
  text-decoration: ${(props) => props.textDecoration};
  flex-shrink: ${(props) => `${props.shrink}`};
  text-underline-position: ${(props) => `${props.textUnderlinePosition}`};
  ${(props) =>
    props.ellipsis &&
    `
    text-overflow: ellipsis;
    white-space: ${(properties) => properties.whiteSpace || 'nowrap'};
    overflow: hidden;
    width: ${(properties) => properties.width || '100%'};
    display: inline-block;
    `}
`

StyledText.defaultProps = {
  position: 'relative',
  width: 'auto',
  height: 'auto',
  margin: 0,
  padding: 0,
  size: 'inherit',
  font: 'inherit',
  weight: 'normal',
  color: 'inherit',
  background: 'inherit',
  align: 'center',
  opacity: '1',
  boxSizing: 'border-box',
  whiteSpace: 'inherit',
  lineHeight: 'inherit',
  textDecoration: 'none',
  shrink: '1',
}

const Text = (props, context) => {
  const { muiTheme } = context
  const { id } = props
  const customThemeProps = muiTheme.ids[id] || {}

  const themeProps = {
    font: muiTheme.rawTheme.fontFamily,
    color: muiTheme.textField.textColor,
    background: muiTheme.textField.backgroundColor,
  }

  const internals = {
    ...themeProps,
    ...customThemeProps,
    ...props,
  }

  if (internals.formattedMessage) {
    const includeTag = (tag) => (str) => str.includes(tag)
    const containTags = (arr = [], tag) => arr.every(includeTag(tag))

    const entries = /({.*})/gm
    const tag = /(<(?:.|\n)*?>)/gm
    const tags = /(<(?:.|\n)*?>*<(?:.|\n)*?>)/gm
    const parts = internals.formattedMessage.split(tags)

    return (
      <UILayout display="block" width="auto" textAlign={internals.align || 'inherit'}>
        {parts.map((part) => {
          let properties = { ...internals }
          let text = part.replace(entries, (match) => properties[match.replace(/{|}/g, '')])

          if (tag.test(part)) {
            text = text.replace(tag, '')
            const decoratedTags = part.match(tag)

            if (containTags(decoratedTags, 'b')) {
              properties.weight = 'bold'
            }

            if (containTags(decoratedTags, 'action')) {
              const actionClick = () => {
                const actions = parts.filter(includeTag('action'))
                const index = actions.findIndex((action) => action.includes(text))

                props.onAction(index)
              }

              properties.weight = 'bold'
              properties.cursor = 'pointer'
              properties.textDecoration = 'underline'
              properties.onClick = actionClick
            }

            if (internals.selectionStyles) {
              properties = {
                ...properties,
                ...internals.selectionStyles,
              }
            }
          }

          return <StyledText {...properties} text={text} />
        })}
      </UILayout>
    )
  }

  return <StyledText {...internals} />
}

Text.contextTypes = {
  muiTheme: PropTypes.object,
}

Text.defaultProps = {
  onAction: () => {},
}

Text.propTypes = {
  id: PropTypes.any,
  onAction: PropTypes.function,
}

export default Text
