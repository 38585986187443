import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { pathOr } from 'ramda'
import moment from 'moment'

import { UIIcon, UILayout, UIText, UIImage } from '../UI'


export default class Ticket extends PureComponent {
  static propTypes = {
    reservation: PropTypes.object.isRequired
  };

  getLabel = (id) => {
    const { intl } = this.context
    if (intl) return intl.formatMessage({ id })
    return ''
  }

  render() {
    const { intl, muiTheme } = this.context
    const { sailRef = {}, reservationOwner = {}, items = [], qrCode, vessel = {}, portTo = {}, portFrom = {} } = this.props.reservation

    const { firstName = '', lastName = '' } = reservationOwner
    const { arrivalTo, departureAt, departureDate, departureFrom, departureTime } = sailRef

    if (!vessel.title) {
      return (
        <UILayout width="100%" j-flex-center>
          <CircularProgress />
        </UILayout>
      )
    }

    return (
      <UILayout borderRadius="10px" bgColor="#4a90e2" column padding="19px 36px" style={{ position: 'relative' }} sms={{ padding: '17px' }}>

        <UILayout width="100%" height="22px" padding="0" style={{ position: 'absolute', top: '123px', left: '0' }} j-flex-end j-flex-space-between xs={0}>
          <svg xmlns="http://www.w3.org/2000/svg" width="5" height="22" viewBox="0 0 5 22">
            <path fill="#1f282c" fillRule="evenodd" d="M5 10.916L0 21.833V0z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="5" height="22" viewBox="0 0 5 22">
            <path fill="#1f282c" fillRule="evenodd" d="M0 10.916l5 10.917V0z" />
          </svg>
        </UILayout>

        <UILayout width="100%" height="22px" padding="0" style={{ position: 'absolute', top: '123px', left: '-1px' }} j-flex-end j-flex-space-between xs={1} xl={0}>
          <svg xmlns="http://www.w3.org/2000/svg" width="5" height="22" viewBox="0 0 5 22">
            <path fill="#1f282c" fillRule="evenodd" d="M5 10.916L0 21.833V0z" />
          </svg>
          <UILayout width="auto" margin="0 -2px 0 0">
            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="22" viewBox="0 0 5 22">
              <path fill="#1f282c" fillRule="evenodd" d="M0 10.916l5 10.917V0z" />
            </svg>
          </UILayout>
        </UILayout>

        <UILayout flex-start j-flex-space-between wrap>
          <UILayout column flex-start width="auto" xs={{ width: '100%' }}>
            <UIText size="14px" xs={{ align: 'center', width: '100%' }} textTransform="capitalize">{this.getLabel('time-trip-details.departure-date')}</UIText>
            <UIText size="16px" color="rgba(255, 255, 255, 0.7)" xs={{ align: 'center', width: '100%' }}>{moment(departureDate).year()}</UIText>
            <UIText size="34px" margin="8px 0 0 0" xs={{ margin: '8px 0 14px 0', align: 'center', width: '100%' }} textTransform="capitalize">{moment(departureAt).locale(intl.locale).format('ddd, MMM D')}</UIText>
          </UILayout>
          <UILayout width="188px" height="112px" bgColor="#fff" borderRadius="8px" padding="2px" j-flex-end center xs={0}>
            <UIIcon type={muiTheme.iconPassenger || 'iconPassenger'} margin="0 20px 0 0" />
            <UIImage src={qrCode} width="108px" height="108px" />
          </UILayout>
        </UILayout>

        <UILayout column margin="0 0 34px 0" xs={{ center: 'true' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="65" height="7" viewBox="0 0 65 7">
            <path fill="#FFF" fillRule="nonzero" d="M59.497 1.388c-.928.517-1.54.857-3.012.857-1.47 0-2.084-.34-3.011-.857C52.362.77 50.979 0 48.415 0c-2.563 0-3.947.77-5.058 1.388-.928.516-1.54.857-3.011.857s-2.084-.34-3.011-.857C36.223.77 34.84 0 32.276 0c-2.563 0-3.946.77-5.058 1.388-.927.516-1.54.857-3.01.857-1.472 0-2.084-.34-3.011-.857C20.085.77 18.702 0 16.138 0c-2.563 0-3.947.77-5.058 1.388-.928.516-1.54.857-3.011.857s-2.084-.34-3.01-.857C3.946.77 2.563 0 0 0v4.21c1.47 0 2.084.341 3.01.857 1.112.619 2.495 1.388 5.059 1.388s3.947-.77 5.058-1.388c.928-.516 1.54-.857 3.011-.857s2.084.341 3.011.857c1.112.619 2.495 1.388 5.058 1.388 2.564 0 3.947-.77 5.059-1.388.927-.516 1.54-.857 3.01-.857 1.472 0 2.084.341 3.012.857 1.11.619 2.494 1.388 5.058 1.388 2.563 0 3.947-.77 5.058-1.388.928-.516 1.54-.857 3.011-.857s2.084.341 3.012.857c1.111.619 2.494 1.388 5.058 1.388 2.564 0 3.947-.77 5.059-1.388.927-.516 1.54-.857 3.011-.857V0c-2.564 0-3.947.77-5.058 1.388z" />
          </svg>
        </UILayout>

        <UILayout flex-start j-flex-space-between margin="0 0 30px 0">
          <UILayout column flex-start width="50%">
            <UIText size="14px" align="left" xsl={{ size: '12px' }} textTransform="uppercase">{portFrom.title}</UIText>
            <UIText size="28px" margin="8px 0 0 0">{departureFrom} </UIText>
          </UILayout>
          <UILayout column flex-start width="36px" sms={{ margin: '0 24px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
              <path fill="#FFF" fillRule="nonzero" d="M31.091 19.684l-4.75 4.76h3.818c0 1.64-.494 3.176-1.495 4.606-.988 1.411-2.34 2.559-4.047 3.44-1.707.88-3.607 1.391-5.666 1.534V9.136h2.058a.97.97 0 0 0 .968-.99.957.957 0 0 0-.968-.97h-2.094c1.566-.44 2.675-1.852 2.675-3.492 0-1.005-.37-1.87-1.091-2.593C19.778.37 18.915 0 17.912 0c-1.003 0-1.87.365-2.586 1.091-.709.719-1.056 1.588-1.056 2.593 0 .832.242 1.558.74 2.205a3.607 3.607 0 0 0 1.882 1.287h-1.9a.957.957 0 0 0-.968.97c0 .55.422.99.968.99h2.024v24.889c-2.077-.125-3.975-.621-5.719-1.502-1.723-.865-3.103-2.006-4.118-3.44-1.008-1.422-1.513-2.98-1.53-4.639H9.66l-4.91-4.76L0 24.444h3.712c.018 2.1.646 4.026 1.918 5.806 1.279 1.784 3.01 3.169 5.173 4.196C12.987 35.485 15.361 36 17.912 36s4.923-.52 7.108-1.554c2.179-1.031 3.901-2.42 5.174-4.196 1.278-1.787 1.917-3.706 1.917-5.806H36l-4.909-4.76zm-14.393-14.8c-.66-.643-.655-1.73 0-2.416.682-.71 1.738-.7 2.429 0 .681.693.686 1.765 0 2.416a1.74 1.74 0 0 1-2.429 0z" />
            </svg>
          </UILayout>
          <UILayout column flex-end width="50%">
            <UIText size="14px" align="right" xsl={{ size: '12px' }} textTransform="uppercase">{portTo.title}</UIText>
            <UIText size="28px" margin="8px 0 0 0">{arrivalTo}</UIText>
          </UILayout>
        </UILayout>

        <UILayout flex-start j-flex-space-between margin="0 0 30px 0">
          <UILayout column flex-start width="auto">
            <UIText size="14px" xs={{ size: '12px' }} textTransform="uppercase">{this.getLabel('reservation.vessel-title')}</UIText>
            <UIText size="20px" margin="14.5px 0 0 0" lineHeight="1" sms={{ size: '18px', margin: '8px 0 0 0' }}>{vessel.title}</UIText>
          </UILayout>
          <UILayout column flex-start width="auto" xs={0}>
            <UIText size="14px" xs={{ size: '12px' }} textTransform="uppercase">{this.getLabel('reservation.class-title')}</UIText>
            <UIText size="20px" margin="13px 0 0 0" sms={{ size: '18px' }} xsl={{ size: '16px' }}>{pathOr('', [[0], 'inventoryItem', 'priceCategory'], items)}</UIText>
          </UILayout>
          <UILayout column flex-end width="auto" sms={{ 'flex-start': true, 'flex-end': false }}>
            <UIText size="14px" xs={{ size: '12px' }} textTransform="uppercase">{this.getLabel('reservation.boarding-title')}</UIText>
            <UIText size="28px" margin="8px 0 0 0" sms={{ size: '18px' }} xsl={{ size: '16px' }}>{moment(departureTime, 'HH:mm').subtract(45, 'minute').format('HH:mm')}</UIText>
          </UILayout>
          <UILayout column flex-end width="auto" sms={{ 'flex-start': true, 'flex-end': false }}>
            <UIText size="14px" xs={{ size: '12px' }} textTransform="uppercase">{this.getLabel('main-view.departure-label')}</UIText>
            <UIText size="28px" margin="8px 0 0 0" sms={{ size: '18px' }} xsl={{ size: '16px' }}>{departureTime}</UIText>
          </UILayout>
        </UILayout>

        <UILayout column flex-start width="auto" margin="0 0 30px 0" xs xl={false}>
          <UIText size="14px" xs={{ size: '12px' }} textTransform="uppercase">{this.getLabel('reservation.class-title')}</UIText>
          <UIText size="28px" margin="8px 0 0 0" sms={{ size: '18px' }} xsl={{ size: '16px' }}>A-CABIN</UIText>
        </UILayout>

        <UILayout column flex-start>
          <UIText size="14px" textTransform="uppercase">{this.getLabel('ticket-form.passenger-text')}</UIText>
          <UILayout flex-end wrap>
            <UIText size="28px" margin="8px 20px 0 0" lineHeight="1" sms={{ size: '18px' }} xsl={{ size: '16px' }}>{`${lastName} ${firstName}`}</UIText>
            { /* <UIToggles style={{ width: 'auto' }}/> */ }
          </UILayout>
        </UILayout>

        {/* additional barcode for mobile */}

        <UILayout height="112px" maxWidth="250px" bgColor="#fff" borderRadius="8px" padding="2px" margin="20px 0 0 0" j-flex-end center xs={1} xl={0}>
          <UIIcon type={muiTheme.iconPassenger || 'iconPassenger'} margin="0 50px 0 0" />
          <UIImage src={qrCode} width="108px" height="108px" />
        </UILayout>

      </UILayout>
    )
  }
}

Ticket.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.any
}
