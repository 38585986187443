import React from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIText, UIIcon } from '../UI'

const arrowStyle = (arrowIndent) => (
  {
    bottom: {
      bottom: '-16px',
      left: `${arrowIndent}`,
      transform: arrowIndent === '50%' ? 'translateX(-50%)' : ''
    },
    top: {
      top: '-16px',
      left: `${arrowIndent}`,
      transform: arrowIndent === '50%' ? 'translateX(-50%) rotate(180deg)' : 'rotate(180deg)'
    },
    left: {
      left: '-22px',
      top: `${arrowIndent}`,
      transform: arrowIndent === '50%' ? 'translateY(-50%) rotate(90deg)' : 'rotate(90deg)'
    },

    right: {
      right: '-20px',
      top: `${arrowIndent}`,
      transform: arrowIndent === '50%' ? 'translateX(-50%) rotate(-90deg)' : 'rotate(-90deg)'
    }
  }
)

const getToolTipTheme = (props, { muiTheme = false }) => muiTheme ? muiTheme.ids[props.id] : {}

class ToolTip extends React.PureComponent {

  constructor() {
    super()
    this.state = {
      showModal: false
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  static propTypes = {
    toolTipContent: PropTypes.any.isRequired,
    toolTipTitle: PropTypes.any,
    toolTipHeader: PropTypes.any,
    additionalStyles: PropTypes.any,
    childrenStyles: PropTypes.any,
    children: PropTypes.any.isRequired,
    toolTipClose: PropTypes.bool,
    arrowPosition: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
    arrowIndent: PropTypes.str,
    id: PropTypes.any
  }

  static defaultProps = {
    toolTipClose: false,
    arrowIndent: '50%',
    toolTipHeader: false,
    id: 'toolTip'
  }

  handleOpenModal() {
    this.setState({ showModal: true })
  }

  handleCloseModal() {
    this.setState({ showModal: false })
  }

  render() {
    const { toolTipContent, toolTipTitle, toolTipClose, arrowPosition, arrowIndent, toolTipHeader, additionalStyles, children, childrenStyles } = this.props
    const customThemeProps = getToolTipTheme(this.props, this.context)

    return (
      <UILayout center j-flex-center {...childrenStyles} position="relative">
        <UILayout
          width="auto"
          column
          center
          j-flex-center
          onClick={!this.state.showModal ? this.handleOpenModal : this.handleCloseModal}
        >
          {children}
        </UILayout>
        <UILayout
          width="auto"
          maxWidth="100%"
          position="absolute"
          display-if={this.state.showModal}
          {...customThemeProps}
          {...additionalStyles}
        >
          <UILayout padding="10px" column flex-start>
            <UILayout
              padding="10px 34px"
              j-flex-start
              borderBottom="1px solid #599be8"
              display-if={toolTipTitle && !toolTipHeader}
              xs={{ padding: '10px' }}
            >
              <UIText uppercase size="18px" textTransform="uppercase" color="#fffefe">{toolTipTitle}</UIText>
            </UILayout>
            {toolTipHeader}
            {toolTipContent}
          </UILayout>

          <UILayout
            onClick={this.handleCloseModal}
            style={{ position: 'absolute', top: '20px', right: '20px', cursor: 'pointer' }}
            width="auto"
            display-if={toolTipClose}
          >
            <UIIcon type="iconClose" fill="white" />
          </UILayout>

          <UILayout
            position="absolute"
            height="18px"
            width="28px"
            style={arrowStyle(arrowIndent)[arrowPosition]}
            display-if={arrowPosition}
          >
            <UIIcon type="triangle" fill={additionalStyles.bgColor || customThemeProps.bgColor} />
          </UILayout>

        </UILayout>
      </UILayout>
    )
  }
}

ToolTip.contextTypes = {
  muiTheme: PropTypes.object
}

export default ToolTip
