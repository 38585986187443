import Loadable from 'react-loadable'

import Image from './Image'
import Layout from './Layout'
import Text from './Text'
import Block from './Block'
import Checkbox from './Checkbox'
import Dialog from './Dialog'
import Fonts from './Fonts'
import List from './List'
import Toggle from './Toggle'
import Calendar from './Calendar'
import DropDownMenu from './DropDownMenu'
import IncDecButton from './IncDecButton'
import Button from './Button'
import TextField from './TextField'
import PaymentButton from './PaymentButton'
import Accordion from './Accordion'
import Divider from './Divider'
import Stepper from './Stepper'
import FormattedNumber from './FormattedNumber'
import Notification from './Notification'
import Ticket from './Ticket'
import { AvailabilityBar, TicketCard } from './TicketCard'
import TicketParam from './TicketParams'
import BottomSlider from './BottomSlider'
import ToolTip from './ToolTip'
import Tabs from './Tabs'
import ButtonGroup from './ButtonGroup'
import Pagination from './Pagination'

const Icon = Loadable({
  loader: () => import('./Icon'),
  loading: () => null
})

export {
  Image,
  Layout,
  Text,
  Block,
  Checkbox,
  Dialog,
  Icon,
  Fonts,
  List,
  Toggle,
  Calendar,
  DropDownMenu,
  IncDecButton,
  Button,
  TextField,
  PaymentButton,
  Pagination,
  Accordion,
  Divider,
  Stepper,
  FormattedNumber,
  Notification,
  Ticket,
  AvailabilityBar,
  TicketCard,
  TicketParam,
  BottomSlider,
  ToolTip,
  Tabs,
  ButtonGroup
}
