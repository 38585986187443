import React from 'react'
import PropTypes from 'prop-types'

const List = (props) => props.children ? <div>{props.children}</div> : null

List.contextTypes = {
  muiTheme: PropTypes.object
}

List.propTypes = {
  children: PropTypes.any
}

export default List
