import React from 'react'
import PropTypes from 'prop-types'
import SVGInline from 'react-svg-inline'
import Icons from '../src/icons'

const RadioButtons = (props) => {
  const { options, select, id } = props
  return (<div>{options.map((option) => <div key={option}><input
    type="radio" name={id} value={option}
    onClick={() => select(option)}
  />{option}</div>)}</div>)
}

RadioButtons.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  select: PropTypes.func.isRequired
}

const ConfirmButton = (props) => {
  const { label, disabled, confirmReservation } = props
  return <input type="button" value={label} disabled={disabled} onClick={confirmReservation} />
}

ConfirmButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  confirmReservation: PropTypes.func.isRequired
}

const Button = (props) => {
  const { label, disabled, click } = props
  return <input type="button" value={label} disabled={disabled} onClick={click} />
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  click: PropTypes.func.isRequired
}

const Block = (props) => <div>{props.children}</div>

Block.propTypes = {
  children: PropTypes.any
}

const AppBar = (props) => {
  const children = [].concat(props.children || [<span align="title">AppBar</span>])
  const title = children.filter((e) => e.props.align && e.props.align === 'title')
  const left = children.filter((e) => e.props.align && e.props.align === 'left')
  const right = children.filter((e) => e.props.align && e.props.align === 'right')

  return (<div className="app-bar" style={{ overflow: 'overlay' }}>
    <div className="left" style={{ float: 'left' }}>{left}</div>
    <div className="title" style={{ float: 'left' }}>{title}</div>
    <div className="right" style={{ float: 'right' }}>{right}</div>
  </div>)
}

AppBar.propTypes = {
  children: PropTypes.any
}

const Icon = (props) => {
  const internals = { ...props }
  if (internals.fill) internals.cleanup = [].concat(internals.cleanup, 'fill')
  if (internals.cleanup && internals.cleanup.length === 0) internals.cleanup = true
  return <SVGInline svg={Icons[props.type]} {...internals} />
}

Icon.propTypes = {
  type: PropTypes.oneOf(Object.keys(Icons)).isRequired
}

const Text = () => null

Text.propTypes = {}

export default {
  AppBar,
  ConfirmButton,
  RadioButtons,
  Button,
  Block,
  Icon,
  Text,
  Container: () => {}
}
