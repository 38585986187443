import React from 'react'
import PropTypes from 'prop-types'

import { UIDivider } from '../../../UI'

const isLast = (index, length) => index + 1 === length

const Divider = ({ index, length }) => isLast(index, length) ? null : <UIDivider />

Divider.propTypes = {
  length: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
}

export default Divider
