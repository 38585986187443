import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

class ImageWithCircularProgress extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = props.state || {}
  }

  componentWillMount() {
    const img = new window.Image()
    img.onload = () => this.setState({ imageSource: this.props.src })
    img.src = this.props.src
  }

  render() {
    const { isRender, isMobile, ...props } = this.props
    if (this.state.imageSource || !(props.size && props.width && props.height)) { return <img alt="" {...props} key={props.src} /> }
    return (
      <div style={{ position: 'relative', width: props.width, height: props.height }}>
        <div
          style={{ position: 'absolute', width: props.size, height: props.size, transform: 'translateX(-50%) translateY(-50%)', left: '50%', top: '50%' }}
        >
          <CircularProgress {...props} />
        </div>
      </div>
    )

  }
}

ImageWithCircularProgress.propTypes = {
  state: PropTypes.object,
  src: PropTypes.string.isRequired
}

export default ImageWithCircularProgress
