import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'

import { Tabs as MUITabs, Tab } from 'material-ui/Tabs'

const tabStyle = {
  height: '31px'
}

const selectedTabStyle = {
  borderRadius: '15px',
  backgroundColor: '#0eb1bc',
  boxShadow: 'inset 0 1px 3px 0 #ffffff'
}

// eslint-disable-next-line react/prefer-stateless-function
class Tabs extends Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    initialSelectedIndex: PropTypes.number,
    id: PropTypes.string
  };

  constructor(props, context) {
    super(props, context)
    this.state = {
      value: props.initialSelectedIndex
    }
  }

  handleChange = (value) => {
    this.setState({ value })
  };

  render() {
    const { muiTheme } = this.context
    const { id = 'tabs', children, initialSelectedIndex = 0, isMobile } = this.props
    const customThemeProps = muiTheme.ids[id] || {}
    const xsStyles = isMobile && { flexDirection: 'column', alignItems: 'center' }

    return (
      <MUITabs
        initialSelectedIndex={initialSelectedIndex}
        style={{ width: '100%', ...customThemeProps.header }}
        value={this.state.value}
        onChange={this.handleChange}
        inkBarStyle={{ display: 'none' }}
        tabItemContainerStyle={{
          borderRadius: '15px',
          boxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
          ...customThemeProps.tabWrapperStyle,
          ...xsStyles
        }}
      >
        {Children.map(children, (child, index) => {
          if (!child) return null

          const { value, label } = child.props

          let tabExtraStyle = {}

          if (value === this.state.value || (!this.state.value && index === 0)) {
            tabExtraStyle = { ...selectedTabStyle, ...customThemeProps.selectedTabStyle }
          }

          return (
            <Tab
              label={label}
              value={value}
              buttonStyle={{ ...tabStyle, ...customThemeProps.tabStyle, ...tabExtraStyle }}
            >
              {child}
            </Tab>
          )}
        )}
      </MUITabs>
    )
  }
}

Tabs.contextTypes = {
  muiTheme: PropTypes.object
}

export default Tabs
