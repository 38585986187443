import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { UIIcon, UILayout, UIText } from '../UI'

const BACKGROUND_COLORS = {
  error: '#ffa401',
  usual: 'rgba(0, 0, 0, 0.52)'
}

// eslint-disable-next-line react/prefer-stateless-function
export default class Notification extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  };

  render() {
    const { title, onClose, type } = this.props
    const typeLabel = this.context.intl && this.context.intl.formatMessage({ id: `common-notification.${type}` })
    const titleLabel = (title || this.context.intl) && this.context.intl.formatMessage({ id: `common-notification.${type}-desc` })

    return (
      <UILayout
        center
        zIndex={10}
        width="86%"
        height="90px"
        borderRadius="8px"
        position="absolute"
        margin="10px 0 0 0"
        padding="16px 22px 16px 22px"
        bgColor={BACKGROUND_COLORS[type]}
        transition="all 2s ease 0s"
      >
        <UILayout column flex-start>
          <UIText size="14px" margin="0 0 10px 0">{typeLabel}</UIText>
          <UIText size="24px">{titleLabel}</UIText>
        </UILayout>
        <UILayout width="20px" height="20px" onClick={onClose}>
          <UIIcon style={{ cursor: 'pointer' }} type="iconClose" />
        </UILayout>
      </UILayout>
    )
  }
}

Notification.contextTypes = {
  intl: PropTypes.any
}
