import React from 'react'
import MediaQuery from 'react-responsive'
import { reverse, map, mergeDeepRight, addIndex, last } from 'ramda'

export const getMediaQueryies = ({ forwardedRef, ...props }, media, sizes, DecoratedComponent) => {
  let totalStyles = props
  const summaryProps = reverse(map((key) => {
    totalStyles = mergeDeepRight(totalStyles, { ...props[key] })
    return totalStyles
  }, reverse(media)))

  const queries = addIndex(map)((styles, i) => (
    <MediaQuery key={i} minWidth={i > 0 ? sizes[i - 1] : 0}>
      <MediaQuery maxWidth={sizes[i] - 1}>
        <DecoratedComponent ref={forwardedRef} {...styles} isMobile={i <= 3} />
      </MediaQuery>
    </MediaQuery>
  ), summaryProps)

  return (
    <React.Fragment>
      {queries}
      <MediaQuery minWidth={last(sizes)}>
        <DecoratedComponent ref={forwardedRef} {...props} />
      </MediaQuery>
    </React.Fragment>
  )
}
