import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { memoize } from 'ramda'
import { DayPickerSingleDateController, isInclusivelyBeforeDay, DateRangePicker } from 'react-dates'

import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import 'react-dates/lib/css/_datepicker.css'
import './react-dates_overrides.css'

const isOutsideRange = (day) => isInclusivelyBeforeDay(day, moment().subtract(1, 'day'))

const shouldDisableDate = memoize((availableDates) => (date) => {
  const formattedDate = moment(date).format('YYYY-MM-DD')
  return !availableDates[formattedDate]
})

class Calendar extends React.PureComponent {
  render() {
    const { select, selectedValue, availableDates, range, ...params } = this.props
    const isDesktop = isWidthUp('sm', this.props.width)

    if (range) {
      return (
        <DateRangePicker
          hideKeyboardShortcutsPanel
          enableOutsideDays={false}
          isOutsideRange={() => false}
          numberOfMonths={isDesktop ? 2 : 1}
          {...params}
        />
      )
    }

    return (
      <DayPickerSingleDateController
        {...params}
        focused
        numberOfMonths={1}
        firstDayOfWeek={1}
        enableOutsideDays={false}
        hideKeyboardShortcutsPanel
        date={moment(selectedValue)}
        isOutsideRange={isOutsideRange}
        isDayBlocked={shouldDisableDate(availableDates)}
      />
    )
  }
}

Calendar.contextTypes = {
  muiTheme: PropTypes.object
}

Calendar.defaultProps = {
  range: false
}

Calendar.propTypes = {
  range: PropTypes.bool,
  availableDates: PropTypes.object.isRequired,
  selectedValue: PropTypes.func,
  select: PropTypes.func
}


export default withWidth()(Calendar)
