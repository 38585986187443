import { pathOr } from 'ramda'
import { format } from 'currency-formatter'

export const getFormatConfig = (defaultPattern = '€ ###,###.###') => {
  const currencyFormat = pathOr(defaultPattern, ['brandProps', 'backendConfig', 'formats', 'money'])(window)
  const [first, second] = currencyFormat.split(' ')

  if (first.length < 4) {
    const symbol = first

    return {
      symbol,
      decimal: '.',
      thousand: ',',
      precision: 2,
      format: '%s %v',
    }
  }
  const symbol = second

  return {
    symbol,
    decimal: '.',
    thousand: ',',
    precision: 2,
    format: '%v %s',
  }
}

export const getFormattedValue = (number, defaultPattern = '€ ###,###.###') =>
  format(number, getFormatConfig(defaultPattern))
