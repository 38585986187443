import React from 'react'
import PropTypes from 'prop-types'
import RadioButton from '@material-ui/core/Radio'
import RadioButtonGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import MAppBar from '@material-ui/core/AppBar'
import {
  Button,
  Checkbox,
  Dialog,
  Image,
  Layout,
  Block,
  Text,
  Icon,
  Fonts,
  List,
  Toggle,
  Calendar,
  DropDownMenu,
  IncDecButton,
  TextField,
  PaymentButton,
  Accordion,
  AvailabilityBar,
  Divider,
  Stepper,
  FormattedNumber,
  Notification,
  Ticket,
  TicketCard,
  TicketParam,
  BottomSlider,
  ToolTip,
  Tabs,
  ButtonGroup,
  Pagination
} from './components'
import { UIContainer } from './UI'

const RadioButtons = ({ options, select, id }) => {
  const project = (option) => (
    <RadioButton
      key={option}
      value={option}
      name={id}
      label={option}
      onClick={() => select(option)}
    />
  )

  return (
    <RadioButtonGroup
      name={id}
      onChange={(event, val) => {
        select(val)
      }}
    >{options.map(project)}
    </RadioButtonGroup>
  )
}

RadioButtons.propTypes = {
  options: PropTypes.array.isRequired,
  select: PropTypes.func.isRequired,
  id: PropTypes.array.isRequired
}

const ConfirmButton = (props) => {
  const { label, disabled, confirmReservation } = props
  return <Button variant="raison" primary label={label} disabled={disabled} onClick={confirmReservation}></Button>
}

ConfirmButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  confirmReservation: PropTypes.func.isRequired
}

const appBarStyle = {
  positionAbsolute: {
    top: 'auto',
    right: 'auto'
  }
}

// eslint-disable-next-line react/prefer-stateless-function
@withStyles(appBarStyle)
class AppBar extends React.Component {
  render() {
    const { isRender, isMobile, ...props } = this.props
    const children = [].concat(props.children || <span align="title">AppBar</span>)
    const title = children.filter((e) => e.props.align && e.props.align === 'title')
    let left = children.filter((e) => e.props.align && e.props.align === 'left')
    let right = children.filter((e) => e.props.align && e.props.align === 'right')

    const handleTouchTap = props.click || (() => {
    })

    left = left.length === 0 ? null : <div>{left}</div>
    right = right.length === 0 ? null : <div>{right}</div>

    return (<MAppBar
      {...props}
      title={title}
      onTitleTouchTap={handleTouchTap}
      iconElementLeft={left}
      iconElementRight={right}
    />)
  }
}

AppBar.propTypes = {}

export default{
  AppBar,
  ConfirmButton,
  RadioButtons,
  Button,
  Checkbox,
  Dialog,
  Icon,
  Image,
  Block,
  Text,
  Layout,
  Fonts,
  List,
  Toggle,
  Calendar,
  DropDownMenu,
  IncDecButton,
  TextField,
  PaymentButton,
  Accordion,
  AvailabilityBar,
  Divider,
  Stepper,
  Container: UIContainer,
  FormattedNumber,
  Notification,
  Ticket,
  TicketCard,
  TicketParam,
  BottomSlider,
  ToolTip,
  Tabs,
  ButtonGroup,
  LinearProgress,
  Pagination
}
