import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIText } from '../../UI'

export default class FromToTravel extends PureComponent {
  static propTypes = {
    showArrivalTip: PropTypes.bool.isRequired,
    arrival: PropTypes.object.isRequired,
    departure: PropTypes.object.isRequired
  };

  static defaultProps = {
    arrival: {},
    departure: {}
  }

  render() {
    const { arrival, departure, showArrivalTip } = this.props

    return (
      <UILayout >
        <UILayout column style={{position:'relative'}}>
          <UILayout border="solid 1px #BFC3C7" padding="0 21px 0 30px" height="40px" margin="0 0 10px 0" center j-flex-space-between>
            <UIText color="#000" size="18px" style={{textTransfrom: 'uppercase'}}>{departure.portName || departure.port}</UIText>
            <UIText color="#000" size="18px">{departure.time}</UIText>
          </UILayout>

          <UILayout position="relative" border="solid 1px #BFC3C7" height="40px" padding="0 21px 0 30px"  margin="0 0 10px 0" center j-flex-space-between>
            <UIText color="#000" size="18px" style={{textTransfrom: 'uppercase'}}>{arrival.portName || arrival.port}</UIText>
            <UIText color="#000" size="18px">{arrival.time}</UIText>
            <UILayout position="absolute" right="5px" top="0px" width="auto">
              <UIText display-if={showArrivalTip} color="#d0021b" size="18px">*</UIText>
            </UILayout>
          </UILayout>

          <UILayout column style={{position:'absolute', top:'18px', left:'16px'}}>
            <UILayout width="5px" height="4px" borderRadius="50%" bgColor="#26aa0a">&nbsp;</UILayout>
            <UILayout width="1px" height="48px" bgColor="#BFC3C7" margin="0 0 0 1.5px">&nbsp;</UILayout>
            <UILayout width="5px" height="4px" borderRadius="50%" bgColor="#f33001">&nbsp;</UILayout>
          </UILayout>
        </UILayout>  
      </UILayout>
    )
  }
}
