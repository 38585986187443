import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import { THEME } from '../../themes'

const SizedPaper = styled(Paper)`
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  height: ${(props) => props.height};
  background-color: ${(props) => props.bgColor};
  border-radius: ${(props) => props.borderRadius};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.align};
  padding-bottom: ${(props) => props.pb || 'auto'};
  box-shadow: ${(props) =>
    window.brandProps.theme === THEME.LIINILAEVAD ? 'none !important' : `${props.boxShadow} !important`};
  box-sizing: ${(props) => `${props.boxSizing} !important`};
`

SizedPaper.defaultProps = {
  width: '100%',
  minWidth: 'inherit',
  height: 'auto',
  margin: 'auto',
  padding: 'auto',
  align: 'auto',
}

const Block = (properties, context) => {
  const { isRender, isMobile, ...props } = properties
  const { muiTheme } = context
  const borderRadius = props.radius || (muiTheme ? muiTheme.borderRadius : '0px')
  const backgroundColor = props.bgColor || (muiTheme ? muiTheme.paper.backgroundColor : 'transparent')
  const internals = {
    boxShadow: [
      props.isBorder ? 'inset 0px 0px 0px 1px rgba(237,237,237,1),' : '',
      muiTheme ? muiTheme.boxShadow : '0 14px 4px 0 rgba(0, 0, 0, 0.06)',
    ].join(' '),
    ...props,
    bgColor: backgroundColor,
    borderRadius,
    style: {
      backgroundColor,
      borderRadius,
    },
    zDepth: 3,
  }
  return <SizedPaper {...internals}>{props.children}</SizedPaper>
}

Block.contextTypes = {
  muiTheme: PropTypes.any,
}

Block.defaultProps = {
  isBorder: true,
  children: PropTypes.any,
}

Block.propTypes = {
  radius: PropTypes.string,
  bgColor: PropTypes.string,
  isBorder: PropTypes.bool,
  children: PropTypes.any,
}

export default Block
