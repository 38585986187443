/* eslint-disable react/no-multi-comp */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { omit } from 'ramda'

import MaterialUI from './MaterialUI'
import HTML5UI from './HTML5UI'
// eslint-disable-next-line no-unused-vars
import ResponsiveProps, { AbstractPureComponent } from './ResponsiveProps'

const changeUI = 'UI:CHANGE'
const changeUIName = 'UI:CHANGE_NAME'

const changeMedia = 'MEDIA:CHANGE'
const changeMediaName = 'MEDIA:CHANGE_NAME'

const UIChangeAction = (uiName, ui) => (dispatch) => {
  dispatch({ type: changeUI, payload: ui })
  dispatch({ type: changeUIName, payload: uiName })
}

const UIReducers = {
  ui: (state = HTML5UI, action) => {
    switch (action.type) {
      case changeUI:
        return Object.assign({}, state, action.payload)
      default:
        return state
    }
  },
  uiName: (state = 'Default', action) => {
    switch (action.type) {
      case changeUIName:
        return action.payload
      default:
        return state
    }
  }
}

const MediaReducers = {
  media: (state = [], action) => {
    switch (action.type) {
      case changeMedia:
        return Object.assign({}, state, action.payload)
      default:
        return state
    }
  },
  mediaName: (state = '', action) => {
    switch (action.type) {
      case changeMediaName:
        return action.payload
      default:
        return state
    }
  }
}

class CatchMediaChanges extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired
  }

  componentWillReceiveProps(newProps, newContext) {
    if (this.context.media.length !== newContext.media.length) {
      this.props.dispatch({ type: changeMedia, payload: newContext.media })
      this.props.dispatch({
        type: changeMediaName,
        payload: newContext.media.length > 0 ? newContext.media[newContext.media.length - 1] : ''
      })
    }
  }

  render() {
    return this.props.children
  }
}

CatchMediaChanges.contextTypes = {
  media: PropTypes.array
}

// eslint-disable-next-line react/no-multi-comp
class Provider extends React.Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired
  }

  static defaultProps = {
    ui: {},
    muiTheme: {}
  }

  getChildContext() {
    const { breakpoints = {} } = this.context.muiTheme
    return {
      ...this.props.ui,
      breakpoints,
      reflexbox: { breakpoints: Object.values(breakpoints) }
    }
  }

  render() {
    return (
      this.props.children
    )
  }
}

Provider.childContextTypes = {
  AppBar: PropTypes.func,
  ConfirmButton: PropTypes.func,
  RadioButtons: PropTypes.func,
  Toggle: PropTypes.func,
  Calendar: PropTypes.func,
  DropDownMenu: PropTypes.func,
  IncDecButton: PropTypes.func,
  TextField: PropTypes.func,
  PaymentButton: PropTypes.func,
  Accordion: PropTypes.func,
  AvailabilityBar: PropTypes.func,
  Divider: PropTypes.func,
  Stepper: PropTypes.func,
  Button: PropTypes.func,
  Checkbox: PropTypes.func,
  Dialog: PropTypes.func,
  Block: PropTypes.func,
  Icon: PropTypes.func,
  Text: PropTypes.func,
  Image: PropTypes.func,
  Layout: PropTypes.func,
  Fonts: PropTypes.func,
  List: PropTypes.func,
  Container: PropTypes.func,
  FormattedNumber: PropTypes.func,
  Notification: PropTypes.func,
  Ticket: PropTypes.func,
  TicketCard: PropTypes.func,
  TicketParam: PropTypes.func,
  reflexbox: PropTypes.any,
  breakpoints: PropTypes.object,
  BottomSlider: PropTypes.any,
  ToolTip: PropTypes.any,
  Tabs: PropTypes.any,
  ButtonGroup: PropTypes.any,
  LinearProgress: PropTypes.any,
  Pagination: PropTypes.any
}

Provider.contextTypes = {
  muiTheme: PropTypes.object,
  media: PropTypes.array
}

const UIProvider = connect(({ ui }) => ({ ui }))(Provider)
@ResponsiveProps()
@AbstractPureComponent('AppBar')
class UIAppBar {
}

@ResponsiveProps()
@AbstractPureComponent('Block')
class UIBlock {
}

@AbstractPureComponent('Tabs')
class UITabs {
}

@ResponsiveProps()
@AbstractPureComponent('Button')
class UIButton {}

@ResponsiveProps()
@AbstractPureComponent('Layout')
class UILayout {}

@ResponsiveProps()
@AbstractPureComponent('ToolTip')
class UIToolTip {}

@ResponsiveProps()
@AbstractPureComponent('Icon')
class UIIcon {}

@AbstractPureComponent('DropDownMenu')
class UIDropDownMenu {}

@ResponsiveProps()
// eslint-disable-next-line react/prefer-stateless-function
class UIText extends React.PureComponent { // eslint-disable-line react/no-multi-comp
  static contextTypes = {
    Text: PropTypes.func,
    intl: PropTypes.any
  }
  static propTypes = {}
  render() {
    const { props, context } = this
    const { Text, intl = false } = context
    const { translate = { id: false }, text = false, translateId } = props
    if (translateId) translate.id = translateId
    const handleProps = { ...props }
    if (intl && translate.id) {
      if (text) {
        handleProps.text = intl.formatMessage(translate, props)
      } else {
        handleProps.children = <FormattedMessage {...translate} />
      }
    }
    return <Text {...handleProps} />
  }
}
@ResponsiveProps()
class UIImage extends React.Component { // eslint-disable-line react/no-multi-comp
  static contextTypes = {
    Image: PropTypes.func,
    media: PropTypes.array
  }
  static propTypes = {
    src: PropTypes.string.isRequired
  }

  shouldComponentUpdate({ src }) {
    const isChangedMedia = this.media !== this.context.media
    this.media = this.context.media
    return this.props.src !== src || isChangedMedia
  }

  media = []

  render() {
    const { src } = this.props
    const { Image } = this.context
    if (!src) return null
    return <Image {...this.props} />
  }
}


const UIRadioButtons = ({ options, id, select }, { RadioButtons: DefRadio }) => (
  <DefRadio options={options} id={id} select={select} />
)

UIRadioButtons.propTypes = {
  options: PropTypes.any.isRequired,
  id: PropTypes.any.isRequired,
  select: PropTypes.any.isRequired
}

UIRadioButtons.contextTypes = {
  RadioButtons: PropTypes.func
}

const UIToggles = (props, { Toggle }) => <Toggle {...props} />

UIToggles.contextTypes = {
  Toggle: PropTypes.func
}

const UICalendars = (props, { Calendar }) => <Calendar {...props} />

UICalendars.contextTypes = {
  Calendar: PropTypes.func
}

const UIIncDecButton = (props, { IncDecButton }) => <IncDecButton {...props} />

UIIncDecButton.contextTypes = {
  IncDecButton: PropTypes.func
}

const UITextField = (props, { TextField }) => <TextField {...props} />

UITextField.contextTypes = {
  TextField: PropTypes.func
}

const UIPaymentButton = (props, { PaymentButton }) => <PaymentButton {...props} />

UIPaymentButton.contextTypes = {
  PaymentButton: PropTypes.func
}

const UIAccordion = (props, { Accordion }) => <Accordion {...props} />

UIAccordion.contextTypes = {
  Accordion: PropTypes.func
}

const UIAvailabilityBar = (props, { AvailabilityBar }) => <AvailabilityBar {...props} />

UIAvailabilityBar.contextTypes = {
  AvailabilityBar: PropTypes.func
}

const UIDivider = (props, { Divider }) => <Divider {...props} />

UIDivider.contextTypes = {
  Divider: PropTypes.func
}

const UIStepper = (props, { Stepper }) => <Stepper {...props} />

UIStepper.contextTypes = {
  Stepper: PropTypes.func
}

const UIChange = connect((state, props) => ({
  options: Object.keys(props.uis || { Default: HTML5UI })
}), { UIChangeAction })((props) => {
  // eslint-disable-next-line no-shadow
  const { options, uis, UIChangeAction } = props
  return (<UIRadioButtons
    id="UIChange"
    options={options}
    select={(val) => UIChangeAction(val, uis[val])}
  />)
})

const UIFonts = (props, { Fonts }) => <Fonts {...omit(['children'], props)} />

UIFonts.contextTypes = {
  Fonts: PropTypes.func
}

UIFonts.propTypes = {
  font2src: PropTypes.any
}

const UIList = (props, { List }) => {
  const other = omit(['children'], props)
  return <List {...other}>{props.children}</List>
}

UIList.propTypes = {
  children: PropTypes.any.isRequired
}

UIList.contextTypes = {
  List: PropTypes.func
}

const UIContainer = (props, context) => {
  const { Block, Text } = context
  const Container = props.container || Block
  const Item = props.item || Text
  const items = props.items || []
  const containerProps = { ...props }
  delete containerProps.children
  delete containerProps.container
  delete containerProps.item
  delete containerProps.items
  return (
    <Container {...containerProps}>{
      items.map((item, index) => {
        const itemProps = {
          ...props,
          ...item
        }
        return <Item key={index} {...itemProps}>{item.value}</Item> // eslint-disable-line react/no-array-index-key
      })
    }
    </Container>
  )
}

UIContainer.propTypes = {
  container: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  items: PropTypes.array.isRequired
}

UIContainer.contextTypes = {
  Block: PropTypes.func,
  Text: PropTypes.func
}

const UIFormattedNumber = (props, { FormattedNumber }) => <FormattedNumber {...props} />

UIFormattedNumber.contextTypes = {
  FormattedNumber: PropTypes.func
}

const UINotification = (props, { Notification }) => <Notification {...props} />

UINotification.contextTypes = {
  Notification: PropTypes.func
}

const UITicket = (props, { Ticket }) => <Ticket {...props} />

UITicket.contextTypes = {
  Ticket: PropTypes.func
}

const UITicketCard = (props, { TicketCard }) => <TicketCard {...props} />

UITicketCard.contextTypes = {
  TicketCard: PropTypes.func
}

const UIBottomSlider = (props, { BottomSlider }) => <BottomSlider {...props} />

UIBottomSlider.contextTypes = {
  BottomSlider: PropTypes.any
}

const UIButtonGroup = (props, { ButtonGroup }) => <ButtonGroup {...props} />

UIButtonGroup.contextTypes = {
  ButtonGroup: PropTypes.any
}


const UITicketParam = (props, { TicketParam }) => <TicketParam {...props} />

UITicketParam.contextTypes = {
  TicketParam: PropTypes.func
}

const UIDialog = (props, { Dialog }) => <Dialog {...props} />

UIDialog.contextTypes = {
  Dialog: PropTypes.func
}

const UICheckbox = (props, { Checkbox }) => <Checkbox {...props} />

UICheckbox.contextTypes = {
  Checkbox: PropTypes.func
}

const UILinearProgress = (props, { LinearProgress }) => <LinearProgress {...props} />

UILinearProgress.contextTypes = {
  LinearProgress: PropTypes.func
}

const UIPagination = (props, { Pagination }) => <Pagination {...props}/>

UIPagination.contextTypes = {
  Pagination: PropTypes.func
}

export {
  UIChangeAction,
  UIChange,
  UIReducers,
  UIProvider,
  UIAppBar,
  UIBlock,
  UIRadioButtons,
  UIToggles,
  UICalendars,
  UIDropDownMenu,
  UIIncDecButton,
  UITextField,
  UIPaymentButton,
  UIAccordion,
  UIAvailabilityBar,
  UIDivider,
  UIStepper,
  UIButton,
  UICheckbox,
  UIDialog,
  UIIcon,
  UIText,
  UIImage,
  UILayout,
  UIFonts,
  UIList,
  UIContainer,
  UIFormattedNumber,
  UINotification,
  UITicket,
  UITicketCard,
  UITicketParam,
  MediaReducers,
  MaterialUI,
  HTML5UI,
  ResponsiveProps,
  UIBottomSlider,
  UIToolTip,
  UITabs,
  UIButtonGroup,
  UILinearProgress,
  UIPagination
}
